<template>
  <div class="page-dvla animate__animated animate__fadeIn pt-4">
    <!-- <img :src="require('@/assets/banners/banner-dvla.png')" class="w-100 mb-4" alt="" /> -->

    <video
      v-if="!isMobile()"
      :src="`${urlBucket}/form/assets/animation-dvla-page.mp4`"
      class="img-fluid mb-4"
      style="border-radius: 120px; display: none"
      autoplay
      muted
      loop
      @loadeddata="
        (e) => {
          e.target.style.display = 'unset';
          hideSpinner('animation');
        }
      "
    ></video>

    <img
      v-else
      :src="`${urlBucket}/form/assets/banner-dvla.png`"
      class="w-100 mb-4"
      @load="
        (e) => {
          e.target.style.display = 'unset';
          hideSpinner('animation');
        }
      "
      style="border-radius: 120px; display: none"
      alt=""
    />

    <div id="animation" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>

    <h5 class="fs-la">Select what you would like to update</h5>
    <div class="mb-5">
      <Checkbox
        :logo="require('@/assets/dvla-vehicule.png')"
        :isLocalImg="true"
        :description="'Driving licence'"
        :informName="'updateDrivinglicense'"
        :value="updateDrivinglicense"
      />

      <Checkbox
        :logo="require('@/assets/V5C.png')"
        :isLocalImg="true"
        :description="'V5C'"
        :informName="'updateV5C'"
        :value="updateV5C"
      />
      <p v-if="errors.dvlaChoice" style="color: #c64a45">* This field is required</p>
    </div>

    <!-- <div v-if="updateDrivinglicense">
      <h5 class="fs-la mb-1">What is your full name?</h5>
      <p class="fs-md mb-2">As it appears on your driving license</p>
      <input
        type="text"
        placeholder="e.g. Mr. Roger Alexander Smith"
        class="mb-5 p-3 w-100 fs-md"
        :value="fullDvlaName"
        @input="(e) => inputChange('fullDvlaName', e.target.value)"
      />
    </div> -->

    <!-- ** Vehicle registration ** -->
    <div v-if="updateV5C">
      <h5 class="fs-la mb-3">What is your vehicle registration?</h5>
      <!-- Inputs -->
      <div class="mb-5" v-for="(item, index) in vehiclesRegistered" :key="index">
        <p v-if="inputValuesOfVehicleRegistrations[index]?.length > 0" style="color: #c64a45">
          Enter your registration number including spaces
        </p>
        <div class="d-flex">
          <div class="p-1 px-2 text-center flag-box">
            <img :src="require('@/assets/flag-uk.png')" width="25" alt="flag-uk" />
            <h5 class="mb-0 fs-la" style="font-weight: 800">GB</h5>
          </div>

          <div style="width: 60%">
            <input
              type="text"
              placeholder=""
              class="mb-2 w-100 input-vehicle-registration"
              :value="inputValuesOfVehicleRegistrations[index]"
              @input="(e) => vehicleRegistration(e.target.value.toUpperCase(), index)"
              @keyup="(e) => ifEmptyDeleteVehicle(e, index)"
            />
            <p
              v-if="index === 0"
              class="text-end text-decoration-underline mb-0 fs-xs c-pointer"
              @click="addVehicle()"
            >
              Add a another vehicle
            </p>
          </div>

          <div
            class="d-flex align-items-center"
            style="height: 58px"
            v-if="
              validateVehicleRegistration(inputValuesOfVehicleRegistrations[index]) &&
              !searching &&
              Object.keys(item.info).length > 0
            "
          >
            <img class="ms-3" :src="require('@/assets/circle-check.png')" alt="circle-check" />
          </div>

          <div
            v-if="Object.keys(item.info).length == 0 && searching"
            class="d-flex align-items-center"
            style="height: 58px"
          >
            <div
              class="ms-3 spinner-border text-info"
              role="status"
              style="width: 2.3rem; height: 2.3rem"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <p v-if="notFoundError && Object.keys(item.info).length == 0" style="color: #c64a45">
          Registration number not found
        </p>

        <div class="card-vehicule p-3 my-3" v-if="Object.keys(item.info).length > 0">
          <h5 style="color: #22a8d6" class="fs-la">{{ item.carRegistration }}</h5>
          <p class="mb-0 fs-sm"><span class="fw-bold">Make:</span> {{ item.info["make"] }}</p>
          <p class="mb-0 fs-sm">
            <span class="fw-bold">Year of Manufacture:</span> {{ item.info["yearOfManufacture"] }}
          </p>
          <p class="mb-0 fs-sm">
            <span class="fw-bold">CO2 Emissions:</span> {{ item.info["co2Emissions"] }}
          </p>
          <p class="mb-0 fs-sm"><span class="fw-bold">Colour:</span> {{ item.info["colour"] }}</p>
          <p class="mb-0 fs-sm">
            <span class="fw-bold">Engine Capacity:</span> {{ item.info["engineCapacity"] }}
          </p>
          <p class="mb-0 fs-sm">
            <span class="fw-bold">Fuel Type:</span> {{ item.info["fuelType"] }}
          </p>
          <p class="mb-0 fs-sm">
            <span class="fw-bold">Type Approval:</span> {{ item.info["typeApproval"] }}
          </p>
          <p class="mb-0 fs-sm">
            <span class="fw-bold">Wheel Plan:</span> {{ item.info["wheelplan"] }}
          </p>
        </div>
        <div v-if="item.img.length > 0">
          <img
            :src="item.img"
            class="img-fluid"
            alt="car-image"
            style="display: none; border-radius: 50px"
            @load="
              (e) => {
                e.target.style.display = 'unset';
                hideSpinner(`car-imag-${index}`);
              }
            "
          />
          <div :id="`car-imag-${index}`" class="text-center my-4">
            <img src="/assets/img/spinner-loading.gif" width="80px" alt="" />
          </div>
          <div
            class="label-pages d-flex p-2 px-3 align-items-center mt-3"
            v-if="item.img.length > 0"
          >
            <img :src="require('@/assets/check-y.png')" width="30px" class="ms-2 me-2" alt="" />
            <p class="ms-1 fs-sm mb-0">Excellent, we've found your car on the database 🎉</p>
          </div>
        </div>
        <p
          v-if="Object.keys(item.info).length > 0 && vehiclesRegistered.length > 1"
          class="text-end mt-2 text-decoration-underline mb-0 fs-xs c-pointer"
          @click="deleteVehicle(index)"
        >
          Remove vehicle
        </p>
      </div>

      <!-- <p v-if="errors.carRegistration" style="color: #c64a45">* Vehicle registration invalid</p> -->
      <!-- Vehicles Registered -->
    </div>

    <!-- <div class="text-center mt-3 mb-4" v-for="(item, i) in vehiclesRegistered" :key="i + 1 * 11">
      <div class="card-vehicule p-3 mb-2" v-if="Object.keys(item.info).length > 0">
        <h5 style="color: #22a8d6" class="fs-la">{{item.carRegistration}}</h5>
        <p class="mb-0 fs-sm">
          <span class="fw-bold">Make:</span> {{item.info['make']}}
        </p>
        <p class="mb-0 fs-sm">
          <span class="fw-bold">Year of Manufacture:</span> {{item.info['yearOfManufacture']}}
        </p>
        <p class="mb-0 fs-sm">
          <span class="fw-bold">CO2 Emissions:</span> {{item.info['co2Emissions']}}
        </p>
        <p class="mb-0 fs-sm">
          <span class="fw-bold">Colour:</span> {{item.info['colour']}}
        </p>
        <p class="mb-0 fs-sm">
          <span class="fw-bold">Engine Capacity:</span> {{item.info['engineCapacity']}}
        </p>
        <p class="mb-0 fs-sm">
          <span class="fw-bold">Fuel Type:</span> {{item.info['fuelType']}}
        </p>
        <p class="mb-0 fs-sm">
          <span class="fw-bold">Type Approval:</span> {{item.info['typeApproval']}}
        </p>
        <p class="mb-0 fs-sm">
          <span class="fw-bold">Wheel Plan:</span> {{item.info['wheelplan']}}
        </p>
      </div>
      <img v-if="item.img.length > 0" :src="item.img" class="img-fluid" alt="car-image" style="border-radius: 50px;" />
    </div> -->
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import Checkbox from "../components/Checkbox";
import { isMobile } from "../utils/isMobile";
import { setRoute } from '../router/setRoute.js';

// @ is an alias to /src

export default {
  name: "PageDvla",
  components: { Checkbox },
  data() {
    return {
      isMobile,
      inputValuesOfVehicleRegistrations: [""],
      notFoundError: false,
      searching: false,
      errors: {
        carRegistration: false,
        dvlaChoice: false,
      },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    validateVehicleRegistration(value) {
      return /(^[A-Z]{2}[0-9]{2} [A-Z]{3}$)|(^[A-Z][0-9]{1,3} [A-Z]{3}$)|(^[A-Z]{3} [0-9]{1,3}[A-Z]$)|(^[0-9]{1,4} [A-Z]{1,2}$)|(^[0-9]{1,3} [A-Z]{1,3}$)|(^[A-Z]{1,2} [0-9]{1,4}$)|(^[A-Z]{1,3} [0-9]{1,3}$)/.test(
        value
      );
    },
    validateInputs() {
      //Vehicle registration
      // if (this.validateVehicleRegistration()) {
      //   this.errors.carRegistration = false;
      // } else {
      //   this.errors.carRegistration = true;
      // }

      if (this.dvlaNeed == "") {
        this.errors.dvlaChoice = true;
      } else {
        this.errors.dvlaChoice = false;
      }
    },
    inputChange(itemName, value) {
      // console.log("inputChange", value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    vehicleRegistration(value, index) {
      Vue.set(this.inputValuesOfVehicleRegistrations, index, value);

      this.notFoundError = false;

      // this.showInstructionRegistration = value.length >= 1 ? true : false;
      // debugger

      if (value.length == 0 && this.vehiclesRegistered.length == 1) {
        this.$store.commit("formStore/setField", [
          `vehiclesRegistered`,
          [{ carRegistration: "", info: {}, img: "" }],
        ]);
      }

      /* we consult the vehicle information with the DVLA */
      if (this.validateVehicleRegistration(value)) {
        this.searching = true;

        let info = fetch(`${this.baseURL}/dvlaInfo/getVehicleInfo?stream=${value}`).then(
          (response) => response.json()
        );
        let img = fetch(`${this.baseURL}/dvlaInfo/getVehicleImg?stream=${value}`).then((response) =>
          response.json()
        );

        Promise.all([info, img])
          .then((res) => {

            let arrayTemp = [...this.vehiclesRegistered];
            //
            arrayTemp[index].carRegistration = value;
            arrayTemp[index].info = res[0];
            arrayTemp[index].img =
              res[1]?.Response?.DataItems?.VehicleImages?.ImageDetailsList[0]["ImageUrl"];
            //
            this.$store.commit("formStore/setField", [`vehiclesRegistered`, arrayTemp]);
          })
          .catch((e) => (this.notFoundError = true))
          .finally(() => (this.searching = false));
      }
      /* we consult the vehicle information with the DVLA */
    },
    addVehicle() {
      let arrayTemp = [...this.vehiclesRegistered];
      arrayTemp.push({ carRegistration: "", info: {}, img: "" });
      this.$store.commit("formStore/setField", [`vehiclesRegistered`, arrayTemp]);
    },
    ifEmptyDeleteVehicle(event, index) {
      if (
        event.key == "Backspace" &&
        event.target.value == "" &&
        this.vehiclesRegistered.length >= 2
      ) {
        // console.log(event.target.value,index);
        let arrayTemp = [...this.vehiclesRegistered];
        arrayTemp.splice(index, 1);
        this.$store.commit("formStore/setField", [`vehiclesRegistered`, arrayTemp]);
      }
    },
    deleteVehicle(index) {
      let arrayTemp = [...this.vehiclesRegistered];
      arrayTemp.splice(index, 1);
      this.$store.commit("formStore/setField", [`vehiclesRegistered`, arrayTemp]);
    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "Updating the DVLA"]);
    this.$store.commit("setField", ["step4", true]);

    this.inputValuesOfVehiclerRegistrations = this.vehiclesRegistered.map(
      (item) => item.carRegistration
    );

    const validatePage = () => {
      // let route = "/about-you";

      // const setRoute = () => {
      //   if (this.informNHS || this.informDentist) {
      //     route = "/general-info-2";
      //     return;
      //   }
      //   if (this.informInsurance) {
      //     route = "/insurance";
      //     return;
      //   }

      //   if (this.informPets) {
      //     route = "/pets";
      //     return;
      //   }

      //   if (
      //     this.informPension ||
      //     this.informCharities ||
      //     this.informGyms ||
      //     this.informHomecare ||
      //     this.informBreakdown
      //   ) {
      //     route = "/general-info-3";
      //     return;
      //   }

      //   if (this.informBanks) {
      //     route = "/banks";
      //     return;
      //   }

      //   if (this.informCouncilTax || this.informWaterSewerage || this.informEnergy) {
      //     route = "/councils";
      //     return;
      //   }

      //   if (this.informMobile) {
      //     route = "/mobile-providers";
      //     return;
      //   }

      //   if (this.informLoyalty) {
      //     route = "/loyalties";
      //     return;
      //   }
      // };
      // setRoute();

      const removeVehiclesInBlank = () => {
        let hasVehicle = this.vehiclesRegistered.find(v=> Object.keys(v.info).length > 0 );
        // console.log(hasVehicle)

        if (hasVehicle){
          let vehicles = this.vehiclesRegistered.filter(v=> Object.keys(v.info).length != 0)
          this.$store.commit("formStore/setField", [`vehiclesRegistered`, vehicles]);
        }

      };

      removeVehiclesInBlank()

      if (this.updateDrivinglicense) {
        this.$store.commit("formStore/setField", [`dvlaNeed`, "license"]);
      }

      if (this.updateV5C) {
        this.$store.commit("formStore/setField", [`dvlaNeed`, "vehicle"]);
      }

      if (this.updateDrivinglicense && this.updateV5C) {
        this.$store.commit("formStore/setField", [`dvlaNeed`, "both"]);
      }

      this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {
        this.$router.push(setRoute(this.state));
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "dvlaNeed",
      "updateDrivinglicense",
      "updateV5C",
      "vehiclesRegistered",
      // "carRegistration",
      "informElectoralRoll",
      "informMobile",
      "informLoyalty",
      "informNHS",
      "informDentist",
      "informPension",
      "informCouncilTax",
      "informWaterSewerage",
      "informStudentFinance",
      "informHMRC",
      "informDWP",
      "informCharities",
      "informGyms",
      "informBanks",
      "informEnergy",
      "informInsurance",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "informOpticians",
    ]),
    ...mapGetters("formStore", ["state"]),
  },
  watch: {
    updateDrivinglicense(val) {
      if (!val) {
        this.$store.commit("formStore/setField", [`dvlaNeed`, ""]);
      }
    },
    updateV5C(val) {
      if (!val) {
        this.$store.commit("formStore/setField", [
          `vehiclesRegistered`,
          [{ carRegistration: "", info: {}, img: "" }],
        ]);

        this.$store.commit("formStore/setField", [`dvlaNeed`, ""]);
      }
    },
    vehiclesRegistered(val) {
      this.inputValuesOfVehiclerRegistrations = this.vehiclesRegistered.map(
        (item) => item.carRegistration
      );
    },
  },
};
</script>
<style lang="scss">
.page-dvla {
  .flag-box {
    background-color: #1f476f;
    height: 56px;
    border-radius: 0.4rem 0 0 0.4rem;
  }

  .input-vehicle-registration {
    padding: 5.5px;
    padding-left: 15px;
    background-color: #f9c038;
    border-radius: 0 0.375rem 0.375rem 0;
    color: #1f476f;
    font-size: 30px;
  }

  .card-vehicule {
    position: relative;
    border: 2px solid #22a8d6;
    border-radius: 8px;

    p {
      text-align: justify;
      text-justify: inter-word;
    }
  }
}
</style>
